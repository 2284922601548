/*
 * AUTO-GENERATED FILE. DO NOT MODIFY
 *
 * This file was automatically generated by the
 * npm run generate-design-token command, and it
 * should not be modified by hand.
 */

export const ButtonSecondaryBorderWidthDefault = '1px'
export const ButtonSecondaryBorderWidthHovered = '1px'
export const ButtonSecondaryBorderWidthPressed = '1px'
export const ButtonSecondaryBorderWidthDisabled = '1px'
export const ButtonSecondaryColorPositiveDefaultBackground = '#ffffff00'
export const ButtonSecondaryColorPositiveDefaultOnBackground = '#ED9341'
export const ButtonSecondaryColorPositiveDefaultOutline = '#0000001f'
export const ButtonSecondaryColorPositiveHoveredBackground = '#00000014'
export const ButtonSecondaryColorPositiveHoveredOnBackground = '#ED9341'
export const ButtonSecondaryColorPositiveHoveredOutline = '#0000001f'
export const ButtonSecondaryColorPositivePressedBackground = '#ed934129'
export const ButtonSecondaryColorPositivePressedOnBackground = '#ED9341'
export const ButtonSecondaryColorPositivePressedOutline = '#0000001f'
export const ButtonSecondaryColorPositiveDisabledBackground = '#ffffff00'
export const ButtonSecondaryColorPositiveDisabledOnBackground = '#00000061'
export const ButtonSecondaryColorPositiveDisabledOutline = '#0000001f'
export const ButtonSecondaryColorDestructiveDefaultBackground = '#ffffff00'
export const ButtonSecondaryColorDestructiveDefaultOnBackground = '#f4511e'
export const ButtonSecondaryColorDestructiveDefaultOutline = '#0000001f'
export const ButtonSecondaryColorDestructiveHoveredBackground = '#f4511e14'
export const ButtonSecondaryColorDestructiveHoveredOnBackground = '#f4511e'
export const ButtonSecondaryColorDestructiveHoveredOutline = '#0000001f'
export const ButtonSecondaryColorDestructivePressedBackground = '#f4511e29'
export const ButtonSecondaryColorDestructivePressedOnBackground = '#f4511e'
export const ButtonSecondaryColorDestructivePressedOutline = '#0000001f'
export const ButtonSecondaryColorDestructiveDisabledBackground = '#ffffff00'
export const ButtonSecondaryColorDestructiveDisabledOnBackground = '#00000061'
export const ButtonSecondaryColorDestructiveDisabledOutline = '#0000001f'
export const ButtonSecondaryColorWarningDefaultBackground = '#ffffff00'
export const ButtonSecondaryColorWarningDefaultOnBackground = '#fb8c00'
export const ButtonSecondaryColorWarningDefaultOutline = '#0000001f'
export const ButtonSecondaryColorWarningHoveredBackground = '#fb8c0014'
export const ButtonSecondaryColorWarningHoveredOnBackground = '#fb8c00'
export const ButtonSecondaryColorWarningHoveredOutline = '#0000001f'
export const ButtonSecondaryColorWarningPressedBackground = '#fb8c0029'
export const ButtonSecondaryColorWarningPressedOnBackground = '#fb8c00'
export const ButtonSecondaryColorWarningPressedOutline = '#0000001f'
export const ButtonSecondaryColorWarningDisabledBackground = '#ffffff00'
export const ButtonSecondaryColorWarningDisabledOnBackground = '#00000061'
export const ButtonSecondaryColorWarningDisabledOutline = '#0000001f'
